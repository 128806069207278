.CompaniesModal {

  .CompaniesModal-FilterHeader {
    display: flex;
    gap: 1rem;
    padding-bottom: 0.7rem;

    .labeled.multiple.dropdown.icon {
      .divider.text {
        padding-top: 3px;
        margin: 0;
      }
    }

    .SelectCompanies {
      button {
        padding: 1px 10px 1px 10px;
        margin-top: 2px;
        margin-bottom: 2px;

        .icon {
          padding-right: 5px;
        }
        span {
          padding-right: 5px;
        }
      }
    }
  }

  .CompaniesModal-FilterHeader__divider {
    flex-grow: 1;
  }

  .table_content.main-content {
    table {
      thead {
        tr {
          th:nth-last-child(-n + 2) {
            display: none;
          }
        }
      }
      tbody {
        tr {
          td:nth-last-child(-n + 2) {
            display: none;
          }
        }
      }
    }
  }
}
