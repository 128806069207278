.comments-feed {
  .ui.feed {
    .event {
      border: 1px solid #dedede;
      border-radius: 8px;
      padding: 5px;
      margin-bottom: 3px;

      .summary {
        border-bottom: 1px solid #dedede75;
      }
    }
  }

}
