.dashboard-page {

  min-height: 75vh;

  .card-header {
    min-height: 30px;
  }

  .dashboard-card {
    min-height: 165px;
    margin-top: 7px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .header {
    padding-bottom: 2px !important;
  }

  .footer {
    padding-top: 0 !important;
    padding-bottom: 2px !important;
  }

  .card-column {
    padding-bottom: 10px;
  }

  div.content[name="card-content"] {
    padding: 0;
  }

  div.bm-switch[data-name="switch"] {
    border: none;
  }

  button.button.switch-item[data-name="switch-item"] {
    box-shadow: none !important;
  }

  button.button.switch-item[data-name="switch-item"]:hover {
    box-shadow: none !important;
  }

  .table-card-value {
    font-size: 2em;
  }

  .table-card-value.checked[data-value-gt="2"], .table-card-value.completed[data-value-gt="2"] {
    text-shadow: 1px 1px 1px black, 0 0 1em red
  }

}
