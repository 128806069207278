.ui.form {

  button.po-inv-apply-action {
    width: 365px;
  }

  .inline.fields.po-inv-field .po-inv-filter-item input[list=years] {
    width: 275px;
  }
}

.inline.fields.po-inv-field {
  width: 380px;

  label.po-inv-label {
    width: 80px;
  }

  .po-inv-filter-item {
    width: 300px;
  }
}

.ffpo-invoice-list {
  max-height: 50vh;
  overflow-y: scroll;
}

.ffpo-invoice-item-list {
  max-height: 27vh;
  overflow-y: scroll;
}
