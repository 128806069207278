.ui.sortable.table {
  thead th {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  td.collapsing {
    padding-left: 3px;
    padding-right: 3px;
  }
}
