.linked-document-unlink-icon {
  cursor: pointer;
  transition: transform 5s;
}

.linked-document-unlink-icon:hover {
  transform: scale(1.3);
  color: darkred;
}


.popup-linked-documents-trigger {
  cursor: pointer;
}

div.ui.popup.popup-linked-documents {
  width: 450px;
  min-height: 130px;

  div.ui.list {
    width: 450px;
  }
}