
div.modal.statement-modal {
  width: 940px;
}

div.ui.card.company-debt {
  background-color: #d5f1dd;
}

div.ui.card.our-debt {
  background-color: #f5dede;
}

.center-align {
  text-align: center;
}

.green-text {
  color: green;
}

.red-text {
  color: darkred;
}