div.list[role="list"] {
  div.item[role="listitem"] {
    margin-top: 3px;

    padding: 3px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &.warning {
      background-color: rgba(252, 207, 206, 0.77);
    }
  }
}
