.ui.modal .actions > .button.primary {
  background-color: #2987cd;
  border-radius: 8px;
  box-shadow: 0 0 4px 1px #dddddd;
}

.ui.button, .ui.basic.button {
  border-radius: 8px;
  box-shadow: 0 0 4px 1px #dddddd;
}

.ui.button:hover {
  border-radius: 8px;
  box-shadow: 0 0 0 2px #dddddd;
}

div.ui.buttons {
  border-radius: 8px;

  .ui.button:first-child {
    border-radius: 8px 0 0 8px ;
  }

  .ui.button:last-child {
    border-radius: 0 8px 8px 0 ;
  }
}

div.ui.basic.buttons {
  border-radius: 8px;
}

div.ui.pagination.menu {
  border-radius: 8px;

  a.item:first-child {
    border-radius: 8px 0 0 8px ;
  }
  a.item:last-child {
    border-radius: 0 8px 8px 0 ;
  }
}

div.ui.selection.dropdown, .ui.label {
  border-radius: 8px;
}

div.ui.input.action {
  input {
    border-radius: 8px;
  }
  button.ui.icon.button:last-child {
    border-radius: 0 8px 8px 0;
  }
}

.ui.form {
  div.DayPickerInput,
  .ui.input {
    input {
      border-radius: 8px;
    }
  }
  div.field {
    textarea {
      border-radius: 8px;
    }
  }

}

.app-content {
  .main-content {
    border-radius: 8px;
    border: 1px solid rgb(175,175, 176);
  }
}

.invoice-form .invoice-select .ui.selection.dropdown.search {
  border-radius: 8px;
}

table.ui.table, .ui.segment {
  border-radius: 8px;
}

.rmsc.dropdown-multi-select .dropdown-container {
  border-radius: 8px;
}

.main-content > table.ui.table tr td {
  padding: 0 0 0 2px;
  font-size: 90%;
  button, button:active, button:hover {
    transform: scale(0.9, 0.9);
    z-index: 10;
  }
}

.main-content > table.ui.table tr td.action-buttons {
  .buttons {
    transform: scale(0.8, 0.8);
  }
}

.ui.modal.visible {
  border-radius: 8px;

  .content, .header, .actions {
    border-radius: 8px;
  }
}

.ui.dropdown > .menu.visible {
  border-radius: 8px;
}

.ui.popup.visible {
  border-radius: 8px;
}

.ui.page.modals {
  .ui.form {
    input {
      padding: 5px;
      height: 30px;
    }
    div[role="combobox"] {
      padding: 5px;
      height: 30px;
      min-height: 0;
      input {
        padding: 5px;
        height: 30px;
      }
      i.dropdown.icon {
        top: auto;
      }
    }
    textarea {
      padding: 5px;
    }

    .ui.table {
      td {
        padding: 4px;
      }
    }
  }
}
