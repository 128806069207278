body {
  background: linear-gradient(to right, #01193c 32%, #ffffff 30%);

  &.local-app {
    background: linear-gradient(to right, #dd8901 32%, #ffffff 30%);
  }

  &.qa-app {
    background: linear-gradient(to right, #498101 32%, #ffffff 30%);
  }

  & > iframe {
    pointer-events: none;
  }
}
.ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon{
  right: 0.8em;
  opacity: .5;
}

.ui.modal .actions > .button.primary {
  background-color: #2987cd;
}

div.selection.dropdown {
  i.dropdown.icon.clear::before {
    content: "✘";
  }
}

html, body {
  width: 100vw;
  overflow-x: hidden;
}
