.app-content {
  //background: #FFF;
}

.app-header-row {

  #right-column-select-company {
    text-align: right;
    text-align: -webkit-right;
  }
}

.app-totals {
  min-height: 120px;
  .ui.statistic {
    border-radius: 8px;
    padding: 5px;
    min-width: 100%;
    background: white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2));
    backdrop-filter: blur(1rem);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  }
  .ui.centered.grid>.column:not(.aligned):not(.justified):not(.row), .ui.centered.grid>.row>.column:not(.aligned):not(.justified), .ui.grid .centered.row>.column:not(.aligned):not(.justified) {
    text-align: center;
  }
}

.ui.container {
  width: 99% !important;
  padding-top: 20px;

  .ui.grid > .row.app-actions-row {
    background: rgba(255, 255, 255, 0.3);
    padding: 5px 0;
    border-radius: 8px;
    background: white;
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2));
    backdrop-filter: blur(1rem);
    width: 60%;
    height: 60%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

    .column:first-child {
      padding-left: 2px;
    }
    .column:last-child {
      padding-right: 2px;
    }

    nav {
      margin-left: 2px;
    }

    .pagination.menu {
      margin-right: 15px;
    }

    margin: 15px 15px 20px;
  }
  .ui.grid > .row.app-filters-row {
    padding: 0;
    margin: 0px 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
  }
}
.nav-button-icons {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
  button,
  .create-button {
    background-position: center;
    height: 32px;
    width: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 5px 2px;
  }
}
.icon-dropdown,
.icon-add,
.nav-icon {
  padding: 9px !important;
  width: 56px;
  display: flex !important;
  background-color: #f0f1f9 !important;
  justify-content: center;
  i {
    margin: 0 !important;
  }
}

.ui.centered.grid>.column:not(.aligned):not(.justified):not(.row),
.ui.centered.grid>.row>.column:not(.aligned):not(.justified),
.ui.grid .centered.row>.column:not(.aligned):not(.justified) {
  text-align: center;
}
