.tag-list-chips  {
  .ui.action.input.search .ui.mini.icon.button {
    padding: 0 5px 10px;
  }

  .tag-list-chips-new-tag {
    .ui.circular.empty.label:hover {
      cursor: pointer;
      transform: scale(1.25);
    }

    .ui.circular.label:not(.empty) {
      min-height: 1.5em;
      min-width: 1.5em;
    }

    padding-bottom: 3px;

  }


}
