.ui.modal .actions {
  .ui.negative.button,
  .ui.negative.buttons .button {
    background-color: #e40000;
    color: #fff;
    text-shadow: none;
    background-image: none;
  }
  .ui.positive.button,
  .ui.positive.buttons .button {
    background-color: #21ba45;
    color: #fff;
    text-shadow: none;
    background-image: none;
  }
}

.icon-delete-group {
  border: 1px solid rgb(222,222,222);
  border-left: none;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
  padding-top: 6px;
  padding-bottom: 29px;
  margin-right: -1px;
  cursor: pointer;
}

.icon-delete-group:hover {
  border: 1px solid rgb(187,187,187);
  border-left: none;
  color: #e40000;
}

.approve-table div.select-user-approve.ui.search.dropdown {
  min-width: 200px;
}

