.ui.table td.action-buttons {
  text-align: right; }

.ui.icon.vertical.animated.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 14px !important;
  margin: 0; }
  .ui.icon.vertical.animated.button div.visible.content {
    margin: 0; }
    .ui.icon.vertical.animated.button div.visible.content i {
      margin: 0 2px; }

div.confirm div.actions button.ui.primary.button {
  color: rgba(0, 0, 0, 0.6); }
